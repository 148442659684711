import React, {useEffect} from "react";
import routes from "src/core/common/routes";
import {makeProductsPage} from "src/core/pages/productsPagesFactory";
import {getHomeServerSideProps, getHomeStaticProps} from "src/server/preloader/homePage";
import ProductListSchema from "src/core/seo/components/ProductListSchema";
import * as Sentry from "@sentry/nextjs";
import {getTags} from "src/server/preloader/utils";
import useThemeConfig from "src/themes/useThemeConfig";
import useSite from "src/core/sites/hooks/useSite";
import {isMobile} from "src/core/common/utils";
import InStoreExperience from "src/integrations/inStoreExperience/components/InStoreExperience";
import useCustomHome from "src/integrations/inStoreExperience/hooks/useCustomHome";
import Metadata from "src/core/seo/components/Metadata";
import {isClient} from "src/server/utils/isClient";
import {getData, ServerContextProvider} from "src/server/components/context";

function ThemedHomePage(props) {
  const config = useThemeConfig();
  const ThemedProductsPage = config.pages.HomePage;

  return <ThemedProductsPage {...props} />;
}

const Home = makeProductsPage(() => ({pathname: routes.home}), ThemedHomePage);
const ProductReaderPage = makeProductsPage(
  () => ({
    pathname: routes.home,
  }),
  InStoreExperience
);

function HomePage({metadata, ...props}) {
  const [displayCustomHome, setDisplayCustomHome] = useCustomHome();
  const site = useSite();
  const options = site.getOptions();

  useEffect(() => {
    if (isMobile() && options.hasInStoreExperienceEventFlow()) {
      setDisplayCustomHome(true);
    }
  }, []);

  if (displayCustomHome && isMobile() && options.inStoreExperienceEnabled()) {
    return <ProductReaderPage hideFooter {...props} />;
  }

  return (
    <ServerContextProvider data={getData(props)}>
      {!isClient && <Metadata {...metadata} />}
      <ProductListSchema />
      <Home />
    </ServerContextProvider>
  );
}

export async function ssr_getServerSideProps(context) {
  Sentry.setTags(getTags("getServerSideProps", context));

  return getHomeServerSideProps(context);
}

export async function isr_getStaticProps(context) {
  Sentry.setTags(getTags("getStaticProps", context));

  return getHomeStaticProps(context);
}
export async function isr_getStaticPaths() {
  return {
    paths: [],
    fallback: "blocking",
  };
}

export default HomePage;
