function makeCollectionPageSchemaObject({url, name, description}) {
  return {
    "@type": "CollectionPage",
    "@id": `${url}#webpage`,
    url: url,
    name: name,
    inLanguage: "en-US",
    isPartOf: {
      "@id": `${url}#website`,
    },
    description: description,
  };
}

export default makeCollectionPageSchemaObject;
