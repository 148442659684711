import {Html5Qrcode} from "html5-qrcode";

export default class QRCodeReader {
  constructor() {
    this.started = false;
    this.qrcode = new Html5Qrcode("reader");
  }

  async checkCameraPermissions() {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const cameras = devices.filter(device => device.kind === "videoinput");
    return cameras.some(camera => camera.deviceId !== "");
  }

  async start(onStartSuccessCallback = () => {}, onErrorCallback) {
    try {
      const devices = await Html5Qrcode.getCameras();
      if (devices && devices.length) {
        await this.doStart(onStartSuccessCallback, onErrorCallback);
      }
    } catch (e) {
      const error = new Error(
        "Error getting access to the camera. Please check if your browser has access to the camera. If not, allow it."
      );
      error.code = "failed_to_get_camera";
      onErrorCallback(error);

      throw e;
    }
  }

  async doStart(onStartSuccessCallback, onErrorCallback) {
    try {
      await this.qrcode.start(
        {facingMode: "environment"},
        {
          fps: 10,
          qrbox: {width: 250, height: 250},
          videoConstraints: this.getVideoConstraints(),
        },
        onStartSuccessCallback
      );
      this.started = true;
    } catch (err) {
      const error = new Error(`Couldn't start the QR Code Reader. Error: ${err}`);
      error.code = "failed_to_start_reader";
      onErrorCallback(error);
    }
  }

  getVideoConstraints() {
    const iosVersion =
      /(iPhone|iPad) OS ([1-9]*)/g.exec(window.navigator.userAgent)?.[2] || 0;
    const isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1;
    const reverseWidthAndHeight = isAndroid || parseInt(iosVersion) > 16;

    return {
      aspectRatio: this.getAspectRatio(),
      width: reverseWidthAndHeight ? window.innerHeight : window.innerWidth,
      height: reverseWidthAndHeight ? window.innerWidth : window.innerHeight,
      facingMode: "environment",
    };
  }

  getAspectRatio() {
    return window.innerHeight > window.innerWidth
      ? window.innerHeight / window.innerWidth
      : window.innerWidth / window.innerHeight;
  }

  async stop() {
    await this.qrcode.stop();
    this.started = false;
  }
}
