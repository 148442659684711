import React from "react";
import {version} from "../../../version";
import useShop from "../../../shops/hooks/useShop";
import usePages from "src/core/shops/hooks/usePages";
import useThemeConfig from "src/themes/useThemeConfig";
import {isClient} from "src/server/utils/isClient";
import {useSocialsResource} from "src/state/hooks/useAsyncResource";

function TymberFooter() {
  const [shop] = useShop();
  const {data: socials} = useSocialsResource();
  const {footerPages} = usePages();

  const licenseNumber = isClient ? shop.data?.getLicenseNumber() : "";
  const _version = version.replace("# ", "");

  const config = useThemeConfig();
  const ThemedFooter = config.components.Footer;

  return (
    <ThemedFooter
      socialLinks={socials?.socialLinks || []}
      version={_version}
      licenseNumber={licenseNumber}
      footerPages={footerPages}
    />
  );
}

export default TymberFooter;
