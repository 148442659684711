import React from "react";

const CameraIcon = ({color = "#6BAA32"}) => {
  return (
    <svg
      width="95"
      height="95"
      viewBox="0 0 95 95"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="47.5" cy="47.5" r="47.5" fill={color} fill-opacity="0.2" />
      <path
        transform="translate(25.5,25.5)"
        d="M42.1668 34.8333C42.1668 35.8058 41.7805 36.7384 41.0929 37.4261C40.4053 38.1137 39.4726 38.5 38.5002 38.5H5.50016C4.5277 38.5 3.59507 38.1137 2.90744 37.4261C2.2198 36.7384 1.8335 35.8058 1.8335 34.8333V14.6667C1.8335 13.6942 2.2198 12.7616 2.90744 12.0739C3.59507 11.3863 4.5277 11 5.50016 11H12.8335L16.5002 5.5H27.5002L31.1668 11H38.5002C39.4726 11 40.4053 11.3863 41.0929 12.0739C41.7805 12.7616 42.1668 13.6942 42.1668 14.6667V34.8333Z"
        stroke={color}
        stroke-width="3.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        transform="translate(25.5,25.5)"
        d="M21.9998 31.1667C26.0499 31.1667 29.3332 27.8834 29.3332 23.8333C29.3332 19.7832 26.0499 16.5 21.9998 16.5C17.9497 16.5 14.6665 19.7832 14.6665 23.8333C14.6665 27.8834 17.9497 31.1667 21.9998 31.1667Z"
        stroke={color}
        stroke-width="3.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CameraIcon;
