import useSchemaContext from "src/core/seo/components/useSchemaContext";
import React, {useMemo} from "react";
import Head from "next/head";
import useSite from "src/core/sites/hooks/useSite";
import makeProductsListSchema from "src/core/seo/schema-objects/factories/productsList";
import {getProductCatalogUrl, getProductCategoryUrl} from "src/core/seo/meta";
import {isClient} from "src/server/utils/isClient";
import useCanonicalUrl from "src/core/seo/hooks/useCanonicalUrl";
import useRouter from "src/core/common/hooks/useRouter";

export default function ProductListSchema({
  category = null,
  subcategory = null,
  path = "",
}) {
  const ctx = useSchemaContext();
  const site = useSite();
  const {category: categorySlug, subcategory: subcategorySlug} = useRouter().query;

  const canonicalUrl = useMemo(() => {
    if (path || (!categorySlug && !subcategorySlug))
      return getProductCatalogUrl(ctx) + path;

    return getProductCategoryUrl(subcategorySlug || categorySlug, ctx);
  }, [subcategorySlug, categorySlug, ctx, path]);

  const schemas = makeProductsListSchema(site, category, subcategory, ctx);

  useCanonicalUrl(canonicalUrl);

  return (
    <Head>
      {!isClient && <link rel="canonical" href={canonicalUrl} key={"canonical"} />}
      {schemas.map((schema, index) => (
        <script
          key={`head-link-${index}`}
          type={schema.type}
          dangerouslySetInnerHTML={{__html: schema.innerHTML}}
        />
      ))}
    </Head>
  );
}
