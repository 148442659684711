import React from "react";
import localStorage from "src/core/common/localStorage";
import {detail} from "src/core/api/products";
import Product from "src/core/common/models/product";
import {populateRelations} from "src/core/api/utils";
import useProductPath from "src/menu/hooks/useProductPath";
import QRCodeFramedImage from "../assets/qr-code-framed.svg";
import IPhoneImage from "../assets/iphone.png";

export const IN_STORE_EXPERIENCE_VIEW_MAX_INDEX = 2;

export const IN_STORE_EXPERIENCE_CONFIG = [
  {
    main: "Point the camera to scan the QR code",
    secondary: "Frame the QR Code in center",
    image: QRCodeFramedImage,
    button: "Next",
  },
  {
    main: "Once QR code is scanned the product page will open automatically.",
    secondary: "",
    image: () => <img alt="phone" style={{height: "200px"}} src={IPhoneImage} />,
    button: "Get started",
  },
];

export default function useInStoreExperience() {
  const [, , getProductPathDefinition] = useProductPath();

  const hasInStoreExperienceComplete = localStorage.getItem(
    HAS_IN_STORE_EXPERIENCE_COMPLETE
  );

  function setHasInStoreExperienceComplete(hasInStoreExperienceComplete) {
    localStorage.setItem(HAS_IN_STORE_EXPERIENCE_COMPLETE, hasInStoreExperienceComplete);
  }

  function getProductDetail(url) {
    return detail(url);
  }

  function getProductDetailUrl(productDetail) {
    const product = new Product(
      populateRelations(productDetail.data, productDetail.included)
    );

    return getProductPathDefinition(product);
  }

  return [
    hasInStoreExperienceComplete,
    setHasInStoreExperienceComplete,
    getProductDetail,
    getProductDetailUrl,
  ];
}

const HAS_IN_STORE_EXPERIENCE_COMPLETE = "tymber-user-has-in-store-experience-complete";
