import makeBreadCrumbList from "../objects/breadcrumbList";
import {
  getProductCatalogUrl,
  getProductCategoryUrl,
  getProductSubcategoryUrl,
  getSiteURL,
} from "../../meta";

export default function makeBreadcrumbsProductsListSchema(
  category = null,
  subcategory = null,
  ctx
) {
  const items = [
    {
      id: getSiteURL(ctx),
      name: "Home",
    },
    {
      id: category
        ? getProductCategoryUrl(category.getSlug(), ctx)
        : getProductCatalogUrl(ctx),
      name: category ? category.getName() : "Products",
    },
  ];

  if (subcategory) {
    items.push({
      id: getProductSubcategoryUrl(category.getSlug(), subcategory.getSlug(), ctx),
      name: subcategory.getName(),
    });
  }

  return makeBreadCrumbList(items, ctx);
}
