import React from "react";
import styled from "styled-components";
import useThemeConfig from "src/themes/useThemeConfig";
import {makeBasePage} from "src/core/pages/basePageFactory";

function ProductsPage(props) {
  const config = useThemeConfig();
  const ThemedProductsPage = config.pages.ProductsPage;

  return <ThemedProductsPage {...props} />;
}

export function makeProductsPage(routeFactory, FallbackPage) {
  const BasePage = makeBasePage(routeFactory);

  return props => {
    return (
      <BasePage {...props}>
        {props => (
          <BodyContainer>
            {FallbackPage && <FallbackPage {...props} />}
            {!FallbackPage && <ProductsPage {...props} />}
          </BodyContainer>
        )}
      </BasePage>
    );
  };
}

const BodyContainer = styled.div`
  min-height: 100vh; // necessary because firefox does not support dvh
  min-height: 100dvh;
  overflow: hidden;
`;
